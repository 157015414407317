import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { connect } from 'react-redux'
import store from '../../../store'
import { undoFilterSaga, redoFilterSaga, clearFilterSaga } from '../../../store/saga/filters'
import { setShowHSESaga } from '../../../store/saga'
import { setFareCalculation } from '../../../store/actions/heatmap'
import { changeReviewSubView } from '../../../store/actions/views'
import { filterSelector, demandeForecastSelector, heatmapSelector, viewsSelector } from '../../../store/selectors'

import { FullHeightDefaultButton } from '../../../component/buttons/defaultButton'
import { FilterComponent } from '../../../component/filter/filterTypeFactory'
import SectionBox from '../../../component/sectionBox'
import Switch from '../../../component/Switch'
import { withLocalize, Translate } from 'react-localize-redux'
import Spacer from '../../../component/spacer/spacer'
import ButtonLink from '../../../component/buttonLink'
import { Undo, Redo } from '../../../component/icons'
import { DefaultToggleButtonGroup, FilterToggleButton } from '../../../component/buttons/defaultToggle'

import { reviewSubViewEnum } from '../../../appSetting'

const mapStateToProps = state => {
  const { allFilterValues, isLoading } = demandeForecastSelector(state)
  const { filters } = filterSelector(state)
  const { showHSE, usingFare: isUsingFare } = heatmapSelector(state)
  const { reviewSubView } = viewsSelector(state)

  return ({
    allFilterValues,
    isLoading,
    canUndo: filters.canUndo(),
    canRedo: filters.canRedo(),
    isUsingFare,
    showHSE,
    reviewSubView
  })
}

const dispatchAction = type => store.dispatch(type)

class Filters extends Component {
  render() {
    const { allFilterValues, canUndo, canRedo, isLoading, isUsingFare, showHSE, reviewSubView, translate } = this.props

    const undo = () => dispatchAction(undoFilterSaga())
    const redo = () => dispatchAction(redoFilterSaga())
    const clear = () => dispatchAction(clearFilterSaga())
    const onToggleUsingFare = () => dispatchAction(setFareCalculation(!isUsingFare))
    const onToggleShowHSE = () => dispatchAction(setShowHSESaga(!showHSE))
    const setSubView = view => dispatchAction(changeReviewSubView(view))

    return (
      <SectionBox p={2} mb={2}>
        <Box mb={2} display='flex'>
          <Typography variant='h2'><Translate id='filters.sectionTitle' /></Typography>
          <ButtonLink labelId='filters.clear' onClick={clear} disabled={!(canUndo || canRedo)} />
          <Spacer />
          <Box justifyItems='flex-end'>
            <FullHeightDefaultButton title={translate('filters.undo')} onClick={undo} disabled={!canUndo}>
              <Undo />
            </FullHeightDefaultButton>
            <FullHeightDefaultButton title={translate('filters.redo')} onClick={redo} disabled={!canRedo}>
              <Redo />
            </FullHeightDefaultButton>
          </Box>
        </Box>
        {
          allFilterValues.map(filter => {
            const FilterType = FilterComponent(filter)

            return (
              <Box mb={1} xs={12} key={filter.key}>
                <FilterType isLoading={isLoading} />
              </Box>
            )
          })
        }
        <Box mt={2} mb={2} display='flex' justifyContent='space-between'>
          <DefaultToggleButtonGroup value={reviewSubView} exclusive onChange={(_event, view) => setSubView(view)}>
            <FilterToggleButton title={translate('view.forecasting.filters.heatmap')} value={reviewSubViewEnum.Heatmap}>
              <Translate id='view.forecasting.filters.heatmap' />
            </FilterToggleButton>
            <Box p='0 2px !important' />
            <FilterToggleButton title={translate('view.forecasting.filters.list')} value={reviewSubViewEnum.List}>
              <Translate id='view.forecasting.filters.list' />
            </FilterToggleButton>
            {/* <Box p='0 2px !important' />
            <FilterToggleButton title={translate('view.forecasting.filters.graph')} value={reviewSubViewEnum.Graph}>
              <Translate id='view.forecasting.filters.graph' />
            </FilterToggleButton> */}
          </DefaultToggleButtonGroup>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Switch
            checked={showHSE}
            onChange={onToggleShowHSE}
            labelId='heatmap.toggleShowHSE'
            titleId={showHSE ? 'heatmap.titleShowHSEOn' : 'heatmap.titleShowHSEOff'}
          />
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Switch
            checked={isUsingFare}
            onChange={onToggleUsingFare}
            labelId='heatmap.toggleFare'
            titleId={isUsingFare ? 'heatmap.titleToggleFareOn' : 'heatmap.titleToggleFareOff'}
          />
        </Box>
      </SectionBox>
    )
  }
}

Filters.propTypes = {
  allFilterValues: PropTypes.array.isRequired,
  canUndo: PropTypes.bool.isRequired,
  canRedo: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isUsingFare: PropTypes.bool.isRequired,
  showHSE: PropTypes.bool.isRequired,
  reviewSubView: PropTypes.oneOf(Object.values(reviewSubViewEnum)).isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withLocalize(Filters))
