import { ReceivedUserSettings, RequestedUserSettings, ReceivedUserInfo } from '../../actions/UserSettings'
import { NotAvailable } from '../../../appSetting'

const initialState = {
  pictureUrl: NotAvailable,
  givenName: NotAvailable,
  ODs: [],
  showConfidenceScore: true,
  isLoading: true,
  roles: [],
  isAdmin: false,
  isCalibrate: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ReceivedUserSettings: {
      const { stations, showConfidenceScore, roles } = action.data
      const newRoles = roles ?? initialState.roles
      const isAdmin = newRoles.some(role => role.role === 'Admin')
      const isCalibrate = newRoles.some(role => role.role === 'Calibrate')

      return Object.assign({}, state, {
        ODs: stations ?? initialState.roleODss,
        showConfidenceScore: showConfidenceScore ?? initialState.showConfidenceScore,
        isLoading: false,
        roles: newRoles,
        isAdmin,
        isCalibrate
      })
    }
    case RequestedUserSettings:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ReceivedUserInfo:
      return Object.assign({}, state, { ...action.user })
    default:
      return state
  }
}
