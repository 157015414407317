import {
  ApplySelectorToFilter,
  StartNewFilterValue,
  UpdateFilterValue,
  UndoFilter,
  RedoFilter,
  UpdateSelectors
} from '../../actions/filters'
import { createUndoRedo } from '../undoRedo'

const filtersInitialState = {
  DateRange: [{ startDate: 0, endDate: 0 }],
  ToD: [],
  DoW: [],
  Class: []
}

const initialState = {
  haveSelectors: false,
  selectors: {
    Origin: undefined,
    Destination: undefined,
    POS: undefined,
    DateRange: { startDate: 0, endDate: 0 },
    DateRangeIndex: 0,
    Cabin: undefined,
    PathGroup: undefined
  },
  filters: createUndoRedo(filtersInitialState)
}

const firstValue = array => (array[0] ?? []).value

export default (state = initialState, action) => {
  const newState = {}

  const newFiltersValues = () => {
    const presentFilters = { ...state.filters.present }

    Object.keys(presentFilters).forEach(key => {
      presentFilters[key] = firstValue(action.newFilterValue.filter(f => f.id === key)) ?? [...presentFilters[key]]
    })

    return presentFilters
  }

  switch (action.type) {
    case ApplySelectorToFilter: {
      newState.filters = createUndoRedo({ ...filtersInitialState, DateRange: [state.selectors.DateRange] })

      return Object.assign({}, state, newState)
    }
    case UpdateSelectors:
      return Object.assign({}, state, {
        haveSelectors: true,
        selectors: Object.assign({}, state.selectors, { ...action.selectors })
      })
    case UpdateFilterValue:
      if (action.newFilterValue.length > 0) {
        newState.filters = state.filters.add(newFiltersValues())
      }

      return Object.assign({}, state, newState)
    case StartNewFilterValue:
      if (action.newFilterValue.length > 0) {
        newState.filters = createUndoRedo(newFiltersValues())
      }

      return Object.assign({}, state, newState)
    case UndoFilter:
      newState.filters = state.filters.undo()

      return Object.assign({}, state, newState)
    case RedoFilter:
      newState.filters = state.filters.redo()

      return Object.assign({}, state, newState)
    default:
      return state
  }
}
