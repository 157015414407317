import { getUserName } from '../../../authentication/authentication'
import { getPostdate } from '../PostDate'
import { getUserSetting } from '../UserSettings'
import { getConfigurations } from '../configuration'
import { getPhotoUrl, getFirstName } from '../msGraphApi'

export const ChangeView = 'ChangeView'
export const changeView = view => ({ type: ChangeView, view })

export const ChangeReviewSubView = 'ChangeReviewSubView'
export const changeReviewSubView = reviewSubView => ({ type: ChangeReviewSubView, reviewSubView })

export const ChangingView = 'ChangingView'
export const changingView = isChangingView => ({ type: ChangingView, isChangingView })

export const SetAppLoading = 'SetAppLoading'
export const setAppLoading = isAppLoading => ({ type: SetAppLoading, isAppLoading })

const steps = postdate => [
  getUserSetting(getUserName()),
  getPostdate(postdate),
  getConfigurations(),
  getPhotoUrl(),
  getFirstName()
]

export const loadSettings = postdate => dispatch => {
  return Promise.all(steps(postdate).map(dispatch)).finally(() => dispatch(setAppLoading(false)))
}
