import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Spacer from '../spacer/spacer'

import { Translate, withLocalize } from 'react-localize-redux'
import Language from './language'
import Profil from './profil'
import HeaderLink from './HeaderLink'

import { Link } from 'react-router-dom'
import { routes } from '../../appSetting'
import Layout from '../../views/layout'
import { Logo, sizes } from '../icons'
import { Option } from '../../util/conditionnalComponent'

import { connect } from 'react-redux'
import { userSettingsSelector } from '../../store/selectors'

const style = _theme => ({
  container: {
    alignItems: 'center'
  },
  header: {
    boxShadow: '0 3px 15px rgba(0, 0, 0, 0.07)',
    position: 'relative',
    zIndex: 1
  },
  image: {
    margin: '-13px'
  },
  projectTitle: {
    fontSize: '18px'
  }
})

const mapStateToProps = state => {
  const { isAdmin } = userSettingsSelector(state)
  return ({ isAdmin })
}

const CBox = Option(Box)

const TableFilter = ({ isAdmin, classes, translate }) =>
  <Box className={classes.header}>
    <Layout>
      <Box className={classes.container} display='flex'>
        <Link to={routes.welcome}>
          <Logo size={sizes.doubleBig} className={classes.image} text={translate('project.logoalt')} />
        </Link>
        <Box component='span' ml={1} className={classes.projectTitle}><Translate id='project.title' /></Box>
        <Spacer />
        <Box component='span' ml={2} mr={2}>
          <HeaderLink route={routes.welcome} labelId='project.demandForecast' />
        </Box>
        <Box component='span' ml={2} mr={2}>
          <HeaderLink route={routes.commercialInfluence} labelId='project.commercial' />
        </Box>
        <CBox component='span' ml={2} mr={6} condition={isAdmin}>
          <HeaderLink route={routes.users} labelId='project.users' />
        </CBox>
        <Box component='span' mr={6} />
        <Profil />
        <Language />
      </Box>
    </Layout>
  </Box>

TableFilter.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withLocalize(withStyles(style)(TableFilter)))
