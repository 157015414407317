import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CssBaseline from '@material-ui/core/CssBaseline'
import { withLocalize } from 'react-localize-redux'
import globalTranslations from '../resources/global.json'
import { renderToStaticMarkup } from 'react-dom/server'

import './App.css'
import 'bootstrap-material-design/dist/css/bootstrap-material-design.min.css'

import PageContext from '../component/pageContext/pageContext'
import Header from '../component/header/header'
import ErrorLayout from '../error/error'
import WelcomeView from '../views/welcome'
import LogoutView from '../views/logout'
import LoginRedirectView from '../views/loginRedirectView'
import ForecastingView from '../views/forecasting/forecasting'
import CommercialInfluenceView from '../views/commercialInfluence'
import UsersView from '../views/users'
import Page404 from '../views/page404'

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import { withLoginApi } from '../authentication/loginComponent'

import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import { routes, baseRoute } from '../appSetting'
import { Box } from '@material-ui/core'
import LoadSetting from './LoadSetting.js'

const AuthentificatedLoadSetting = withLoginApi(LoadSetting)

class App extends Component {
  constructor(props) {
    super(props)

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' }
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup }
    })
  }

  render() {
    return (
      <Box className='App' display='flex' flexDirection='column'>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router basename={baseRoute}>
            <Header />
            <ErrorLayout>
              <Switch>
                <Route exact path={routes.loginRedirect} component={LoginRedirectView} />
                <Route exact path={routes.logout} component={LogoutView} />
                <Route path='*'>
                  <AuthentificatedLoadSetting>
                    <Switch>
                      <Route exact path={routes.welcome} component={WelcomeView} />
                      <Route exact path={routes.commercialInfluence} component={CommercialInfluenceView} />
                      <Route exact path={routes.users} component={UsersView} />
                      <Route path={routes.forecasting.toString()} component={ForecastingView} />
                      <Redirect from={routes.forecasting.path} to={routes.welcome} />
                      <Route path='*' component={Page404} />
                    </Switch>
                  </AuthentificatedLoadSetting>
                  <PageContext />
                </Route>
              </Switch>
            </ErrorLayout>
          </Router>
        </ThemeProvider>
      </Box>
    )
  }
}

App.propTypes = { initialize: PropTypes.func.isRequired }

export default withLocalize(App)
