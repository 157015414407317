import React from 'react'
import PropTypes from 'prop-types'

import { SingleSelect, newSelectItemByName, toValue } from '../Select'

import { connect } from 'react-redux'
import store from '../../store'
import { updateSelectors } from '../../store/actions/filters'
import { postdateSelector } from '../../store/selectors'
import { withLocalize } from 'react-localize-redux'

export const dateRangeLabelIds = [
  'dateRangeSelector.shortTerm',
  'dateRangeSelector.mediumTerm',
  'dateRangeSelector.longterm'
]

const getDateRangeValues = translate => [
  newSelectItemByName(0, translate(dateRangeLabelIds[0])),
  newSelectItemByName(1, translate(dateRangeLabelIds[1])),
  newSelectItemByName(2, translate(dateRangeLabelIds[2]))
]

const mapStateToProps = state => {
  const { lowRange, mediumRange, highRange } = postdateSelector(state)
  return ({ lowRange, mediumRange, highRange })
}

function DateRangeComponent({ dateRangeIndex, lowRange, mediumRange, highRange, translate }) {
  const ranges = [lowRange, mediumRange, highRange]
  const dateRangeValues = getDateRangeValues(translate)

  function setDate(value) {
    const index = dateRangeValues.map(toValue).indexOf(value)
    store.dispatch(updateSelectors({ DateRange: ranges[index], DateRangeIndex: index }))
  }

  return (
    <SingleSelect
      elements={dateRangeValues}
      required
      titleId='selectorTitle'
      labelId='dateRangeSelector.title'
      updateValue={setDate}
      value={toValue(dateRangeValues[dateRangeIndex])}
    />
  )
}

DateRangeComponent.propTypes = {
  dateRangeIndex: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  mediumRange: PropTypes.object.isRequired,
  highRange: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export const DateRange = connect(mapStateToProps)(withLocalize(DateRangeComponent))
