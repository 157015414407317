export const filterSelector = state => ({ ...state.filter })
export const demandeForecastSelector = state => ({ ...state.demandeForecast })

export const errorSelector = state => {
  let errors = []

  if (state.influences.isError) errors.push(state.influences.error)
  if (state.error.hasError) errors = state.error.errors

  return ({
    isError: state.influences.isError || state.userSettings.isError || state.error.hasError,
    errors
  })
}

export const influenceSelector = state => ({ ...state.influences })
export const optimizationSelector = state => ({ ...state.optimizations })
export const userSettingsSelector = state => ({ ...state.userSettings })
export const configurationSelector = state => ({ ...state.configurations })
export const usersSelector = state => ({ ...state.users })

export const forecastingSelectorForTableBody = state => ({ isDataLoading: state.demandeForecast.isLoading, isFetchFailed: false })
export const pendingInfluencesSelectorForTableBody = state => ({ isDataLoading: state.influences.isPendingInfluencesLoading, isFetchFailed: state.influences.isError })
export const optimizationSelectorForTableBody = state => ({ isDataLoading: state.optimizations.isLoading, isFetchFailed: false })
export const commercialInfluencesSelectorForTableBody = state => ({ isDataLoading: state.commercialInfluence.isLoading, isFetchFailed: false })
export const usersSelectorForTableBody = state => ({ isDataLoading: state.users.isLoading, isFetchFailed: false })

export const tableMainStateSelector = state => ({ ...state.tableState.main })
export const tablePendingInfluencesStateSelector = state => ({ ...state.tableState.pendingInfluences })
export const tableOptimizationStateSelector = state => ({ ...state.tableState.optimizations })
export const tableCommercialInfluencestateSelector = state => ({ ...state.tableState.commercialInfluences })
export const tableUsersSelector = state => ({ ...state.tableState.users })

export const viewsSelector = state => ({ ...state.views })
export const heatmapSelector = state => ({ ...state.heatmap })
export const postdateSelector = state => ({ ...state.postdate })

export const commercialInfluenceSelector = state => ({ ...state.commercialInfluence })
export const newCommercialInfluenceSelector = state => ({ ...state.newCommercialInfluence })
export const newUserSelector = state => ({ ...state.newUser })
export const rowsSelector = state => ({ ...state.rows })
export const heatmapThresholdSelector = state => ({ ...state.heatmapThreshold })
