import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PageHeader, { pageHeaderTopBottomPadding } from '../../component/PageHeader/pageHeader'
import Layout from '../layout'
import Title from '../../component/title/title'
import TableSelector from '../../component/tableSelector/tableSelector'
import ViewSelector from '../../component/viewSelector/viewSelector'
import ButtonLink from '../../component/buttonLink'
import { Subtitle1 } from '../../component/title/subtitle'

import { Collapse, Fade, Box } from '@material-ui/core'

import { dateRangeLabelIds } from '../../component/tableSelector/dateRange'
import Spacer from '../../component/spacer/spacer'
import { createNewFullPaddedButton } from '../../component/buttons/defaultButton'
import { Search, sizes } from '../../component/icons'

import { connect } from 'react-redux'
import store from '../../store'
import { applySelectors, applyPartialSelectors } from '../../store/saga/filters'
import { filterSelector, postdateSelector, userSettingsSelector, configurationSelector } from '../../store/selectors'

import { mapParamsToSelectors, containAllParams, filterBySelector } from '../../businessRules/dimensions'

import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  const { ODs } = userSettingsSelector(state)
  const { selectors, haveSelectors } = filterSelector(state)
  const { postdate, lowRange, mediumRange, highRange } = postdateSelector(state)
  const { pathTypesSelectItem } = configurationSelector(state)

  return ({ ODs, pathTypesSelectItem, selectors, haveSelectors, postdate, lowRange, mediumRange, highRange })
}

const PaddedButton = createNewFullPaddedButton({ t: 0.75, r: 6, b: 0.75, l: 6 }, '190px')

class Header extends Component {
  state = {
    isHeaderOpen: false
  }

  setHeaderOpen() {
    this.setState({ isHeaderOpen: true })
  }

  setHeaderClose() {
    this.setState({ isHeaderOpen: false })
  }

  componentDidMount() {
    const { haveSelectors, lowRange, mediumRange, highRange, match, ODs, pathTypesSelectItem, postdate } = this.props

    if (!haveSelectors) {
      const ranges = [lowRange, mediumRange, highRange]
      const newSelector = mapParamsToSelectors(match.params, ranges, ODs, pathTypesSelectItem)
      const { service } = ODs.filter(filterBySelector(newSelector))[0] ?? {}

      const action = containAllParams(newSelector) ? applySelectors(newSelector, service, postdate) : applyPartialSelectors(newSelector)
      store.dispatch(action)

      containAllParams(newSelector) ? this.setHeaderClose() : this.setHeaderOpen()
    }
  }

  render() {
    const { isHeaderOpen } = this.state
    const { selectors } = this.props

    const open = () => this.setHeaderOpen()
    const close = () => this.setHeaderClose()

    const closeButton = () =>
      <PaddedButton variant='contained' onClick={close}>
        <Translate id='view.forecasting.cancel' />
      </PaddedButton>

    const dateRangeLabelId = dateRangeLabelIds[selectors.DateRangeIndex]

    return (
      <PageHeader pb={0}>
        <Layout>
          <Box display='flex'>
            <Box pb={pageHeaderTopBottomPadding}>
              <Title labelId='view.forecasting.title'>
                <Fade in={!isHeaderOpen}>
                  {/* Bug in React need to have div */}
                  <div>
                    <ButtonLink labelId='view.forecasting.searchNewButton' onClick={open} endIcon={<Search size={sizes.small} />} />
                  </div>
                </Fade>
              </Title>
              <Fade in={!isHeaderOpen}>
                <Subtitle1>
                  {selectors.Origin}-{selectors.Destination} | {selectors.POS} | {selectors.Cabin} | {selectors.PathGroup} | <Translate id={dateRangeLabelId} />
                </Subtitle1>
              </Fade>
              <Collapse in={isHeaderOpen}>
                <TableSelector showViewSelector onClickSubmit={close} CancelButton={closeButton} />
              </Collapse>
            </Box>
            <Spacer />
            <ViewSelector />
          </Box>
        </Layout>
      </PageHeader>
    )
  }
}

Header.propTypes = {
  ODs: PropTypes.array.isRequired,
  pathTypesSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectors: PropTypes.object.isRequired,
  haveSelectors: PropTypes.bool.isRequired,
  postdate: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  mediumRange: PropTypes.object.isRequired,
  highRange: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withRouter(Header))
