import { ChangeView, ChangeReviewSubView, ChangingView, SetAppLoading } from '../../actions/views'
import { defaultViewSelector, defaultReviewSubView } from '../../../appSetting'

const initialState = {
  view: defaultViewSelector,
  reviewSubView: defaultReviewSubView,
  isChangingView: true,
  isAppLoading: true
}

export default (state = initialState, action) => {
  const { type, view, isChangingView, reviewSubView } = action

  switch (type) {
    case ChangeView:
      return Object.assign({}, state, { view })
    case ChangeReviewSubView:
      return Object.assign({}, state, { reviewSubView })
    case ChangingView:
      return Object.assign({}, state, { isChangingView })
    case SetAppLoading:
      return Object.assign({}, state, { isAppLoading: action.isAppLoading, isChangingView: false })
    default:
      return state
  }
}
