import { saga } from '../../businessRules/saga'

import { changingView, changeView } from '../actions/views'
import { calculateRows } from '../actions/rows'
import { viewSelectorEnum } from '../../appSetting'
import { loadInfluence, changeInfluenceValue, formatLiveInfluence } from '../actions/Influences'
import { setShowHSE, setFareCalculation } from '../actions/heatmap'
import { mergefirstLast } from '../../businessRules/date'
import { startNewFilterValue, newFilter } from '../actions/filters'
import { changePage, main } from '../actions/tableState/tableState'

const setFilters = ({ dow, classes, tod, dateRange }) => startNewFilterValue([newFilter('DoW', dow), newFilter('Class', classes), newFilter('ToD', tod), newFilter('DateRange', dateRange)])

export const reloadinfluenceSaga = row => saga([
  changingView(true),
  setShowHSE(row.DepartureDate.length === 1),
  loadInfluence(row.id),
  setFilters({ dow: row.DoW, classes: row.Class, tod: row.ToD, dateRange: [mergefirstLast(row.DepartureDate)] }),
  changePage(main)(0),
  changeView(viewSelectorEnum.List),
  calculateRows(),
  changingView(false)
])

export const loadOptimizationSaga = row => saga([
  changingView(true),
  [
    // setOptimizationStatus({ ...row, status: 'Read' }),
    setShowHSE(false),
    setFareCalculation(true),
    changeInfluenceValue(row.influence),
    setFilters({ dow: row.DoW, classes: row.Class, tod: row.ToD, dateRange: [row.firstLastDepartureDatesMoment] }),
    changePage(main)(0)
  ],
  calculateRows(),
  formatLiveInfluence(),
  changeView(viewSelectorEnum.List),
  changingView(false)
])

export const setShowHSESaga = showHSE => saga([setShowHSE(showHSE), calculateRows(), formatLiveInfluence()])
