import React from 'react'
import PropTypes from 'prop-types'

// import { createGridFullHeightWithPaddingBottom } from '../../../component/gridViewLayout/gridFullHeight'
// import GridViewLayout from '../../../component/gridViewLayout/gridViewLayout'

import Filters from './filters'
import HeatMap from '../../../component/heatmap/heatmap'
import HeatmapTool from './heatmapTool'
import Statistic from '../../../component/statistic/statistic'
import InfluenceToolBar from './influences'
import TableForecasting from './table'

import PostdateSubTitle from '../../postdateSubTitle'

import { Grid, Box } from '@material-ui/core'
import { Option } from '../../../util/conditionnalComponent'
import { reviewSubViewEnum } from '../../../appSetting'
import { connect } from 'react-redux'
import { viewsSelector } from '../../../store/selectors'

// const GridFullHeightWithPaddingBottom = createGridFullHeightWithPaddingBottom('120px') // Must update layout.js margin.b

const HeatMapOption = Option(HeatMap)
const TableForecastingOption = Option(TableForecasting)

const mapStateToProps = state => ({ reviewSubView: viewsSelector(state).reviewSubView })

const ReviewView = ({ reviewSubView }) =>
  <Box mt={2}>
    <PostdateSubTitle />
    <Grid container>
      <Grid item xs={2}>
        <Filters />
        <InfluenceToolBar />
      </Grid>
      <Grid item xs={9}>
        <Box pr={2} pl={2}>
          <HeatmapTool />
          <HeatMapOption condition={reviewSubView === reviewSubViewEnum.Heatmap} />
          <TableForecastingOption condition={reviewSubView === reviewSubViewEnum.List} />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Statistic />
      </Grid>
    </Grid>
  </Box>

/* <GridViewLayout>
<GridFullHeightWithPaddingBottom container>
  <Grid item xs={12}></Grid>
</GridFullHeightWithPaddingBottom>
</GridViewLayout> */

ReviewView.propTypes = { reviewSubView: PropTypes.number.isRequired }

export default connect(mapStateToProps)(ReviewView)
